<template>
  <v-card class="custom-field-popup-card">
    <v-card-title class="px-5 pt-4 pb-4">
      <div>New Database</div>
    </v-card-title>
    <validation-observer ref="textInput">
      <v-row class="px-2 py-0 mx-0 my-0">
        <v-col cols="12" class="py-0">
          <CustomTextInput
            v-model="displayName"
            header="Display Name"
            placeholder="New Display Name"
            :required="true"
          />
        </v-col>
      </v-row>
      <v-row class="px-2 py-0 mx-0 my-0">
        <v-col cols="12" class="py-0">
          <CustomTextInput
            v-model="hostname"
            header="Hostname"
            placeholder="New Hostname"
            :required="true"
          />
        </v-col>
      </v-row>
      <v-row class="px-2 py-0 mx-0 my-0">
        <v-col cols="12" class="py-0">
          <CustomTextInput
            v-model="user"
            header="Default Username"
            placeholder="New Default Username"
            :required="true"
            autocomplete="off"
          />
        </v-col>
      </v-row>
      <v-row class="px-2 py-0 mx-0 my-0">
        <v-col cols="12" class="py-0">
          <CustomTextInput
            v-model="password"
            type="password"
            header="Default Password"
            placeholder="New Default Password"
            :required="true"
            autocomplete="off"
          />
        </v-col>
      </v-row>
      <v-row class="px-2 py-0 mx-0 my-0">
        <v-col cols="12" class="py-0">
          <hr>
          <div class="mt-3" style="font-weight: bold;">
            Replica Settings
          </div>
        </v-col>
        <v-col
          v-for="(replica, index) in replicas"
          :key="'replica' + index"
          cols="12"
          class="replica"
        >
          <v-row>
            <v-col cols="10" class="py-0">
              <CustomTextInput
                v-model="replica.host"
                placeholder="Replica Server Hostname"
                autocomplete="off"
                required
              />
            </v-col>
            <v-col cols="2" class="py-0">
              <v-btn
                icon
                class="mt-4"
                @click="removeReplicaServer(index)"
              >
                <v-icon color="red">
                  mdi-delete-outline
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" class="py-0">
          <v-btn
            class="custom-button custom-button--blue mt-5"
            height="34px"
            depressed
            @click="addReplicaServer"
          >
            Add Replica
          </v-btn>
        </v-col>
      </v-row>
    </validation-observer>

    <v-card-actions
      class="d-flex justify-end px-5 pb-3 pt-3"
    >
      <div
        :class="cancel_button_class + ' elevation-0 px-7'"
        @click="$emit('dismiss')"
      >
        Cancel
      </div>
      <v-btn
        :class="action_button_class + ' px-6'"
        @click="add"
      >
        Add
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import CustomTextInput from "@/sharedComponents/CustomTextInput";

export default {
  name: "AddNewDatabaseDialog",
  components: {
    CustomTextInput
  },
  props: {
    action_button_class: {
      default: 'simple-text-button button--blue',
      type: String,
    },
    cancel_button_class: {
      default: 'simple-text-button button--gray',
      type: String,
    },
  },
  data() {
    return {
      displayName: '',
      hostname: '',
      user: '',
      password: '',
      replicas: [],
    };
  },
  computed: {},
  created() {},
  methods: {
    async add() {
      if (!await this.$refs.textInput.validate()) {
        return;
      }

      this.$emit("add", {
        displayName: this.displayName,
        hostname: this.hostname,
        user: this.user,
        password: this.password,
        replicaDetails: this.replicas
      });
      this.$emit("dismiss");
    },
    addReplicaServer() {
      this.replicas.push({host: ''});
    },
    removeReplicaServer(index) {
      this.replicas.splice(index, 1);
    },
  },
};
</script>
