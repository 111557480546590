<template>
  <div class="databases-table">
    <v-simple-table>
      <thead>
        <tr class="databases-table__header">
          <th>Display Name</th>
          <th>Hostname</th>
          <th />
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, i) in databases" :key="i">
          <td class="databases-table__header">
            {{ item.displayName }}
          </td>
          <td class="databases-table__header">
            {{ item.hostname }}
          </td>
          <td class="v-menu-attach-parent">
            <v-menu
              v-if="optionListItems.length"
              nudge-top="-5px"
              nudge-left="15px"
              :offset-y="true"
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  class="elevation-0 outlined-btn px-12"
                  height="34px"
                  outlined
                  plain
                  v-bind="attrs"
                  v-on="on"
                  @click="$emit('click')"
                >
                  Actions
                </v-btn>
              </template>
              <OuterList
                title="Actions"
                :items="item.isVerified ? optionListItems.slice(0, -1) : optionListItems"
                @click="$emit('action', { event: $event, item })"
              />
            </v-menu>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
import OuterList from "@/sharedComponents/OuterList";

export default {
  name: "DatabasesTable",
  components: { OuterList },
  props: {
    databases: {
      default: () => [],
      type: Array,
    },
  },
  computed: {
    optionListItems() {
      if (this.$store.getters['user/isPulseAdmin']) {
        return  [
          { label: "Edit item", icon: "mdi-pencil", value: "edit" },
          {
            label: "Delete Item",
            icon: "mdi-delete-outline",
            value: "delete",
          },
        ];
      }

      return [];
    },
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
.databases-table {
  &__header {
    font-size: 13px !important;
    color: #241c15 !important;
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    th {
      font-size: 13px !important;
      color: #241c15 !important;
      font-family: "Open Sans", sans-serif;
      font-weight: 600;
    }
  }
  .v-menu-button:focus {
    background: #2b84eb !important;
    opacity: 1;
    .v-btn__content {
      color: white;
    }
  }
  &__text {
    font-size: 13px !important;
    color: #241c15 !important;
    font-family: "Open Sans", sans-serif;

    &--blue {
      font-size: 13px !important;
      color: #2b84eb !important;
      font-weight: 600;
      font-family: "Open Sans", sans-serif;
    }
  }
  .star-icon {
    position: absolute;
    margin-left: -35px;
    margin-top: 3px;
  }
  .align-button-to-right {
    text-align: right;
    width: 150px;
  }
  .toggle-to-right {
    margin-left: 6vw;
  }

  thead {
    tr > th {
      border-bottom: solid 1px #dee7ee !important;
    }
  }
  tbody {
    // table border colors
    tr {
      height: 77px !important;
      td {
        border-bottom: solid 1px #dee7ee;
      }
    }
    tr:hover {
      background: inherit !important;
    }
  }
  img.img-avatar {
    border-radius: 50%;
    object-fit: contain;
  }
}
</style>
