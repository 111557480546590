<template>
  <v-container class="mt-3 px-sm-10 px-0 elevation-0" fluid>
    <PageHeader
      header-text="Manage Databases"
      class="mb-7 px-3"
      :regular-button-text="
        $store.getters['user/isPulseAdmin'] ? 'NEW DATABASE' : null
      "
      :regular-button-visible="$store.getters['user/isPulseAdmin']"
      @regular-button-click="addDialog = true;"
    />

    <SearchFieldRowWithPagination
      :current-page-number="tablePageNumber"
      :current-page-size="tablePageSize"
      :count-total="totalCount"
      :page-sizes="[25, 50, 100]"
      :init-search-string="searchString"
      search-field-placeholder="Search for hostnames"
      class="ml-1 ml-md-0 mb-4 mb-md-0"
      @search="(keyword) => handleSearch(keyword)"
      @next-page="handleNextPage"
      @prev-page="handlePrevPage"
      @change-page-size="handleChangePageSize"
    />

    <p class="scroll-title px-4 d-md-none">
      You can scroll in the table to see more information of databases
    </p>

    <DatabasesTable
      :databases="databases"
      class="px-3"
      @action="handleAction($event)"
    />

    <v-dialog v-model="addDialog" scrollable max-width="360px">
      <AddNewDatabaseDialog
        v-if="addDialog"
        @add="addDatabase"
        @dismiss="addDialog = false;"
      />
    </v-dialog>

    <v-dialog v-model="editDialog" scrollable max-width="360px">
      <EditDatabaseDialog
        v-if="editDialog"
        :edited-database="editedDatabase"
        @save="editDatabase"
        @dismiss="editDialog = false;"
      />
    </v-dialog>

    <v-dialog v-model="deleteDialog" scrollable max-width="360px" @click:outside="deleteItemId = null">
      <DeleteConfirmationDialog
        v-if="deleteDialog"
        header="Delete Database"
        subheader="Do you really want to delete database?"
        action_button_name="Confirm"
        @delete="deleteDatabase"
        @dismiss="deleteDialog = false; deleteItemId = null;"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import PageHeader from "@/sharedComponents/PageHeader";
import DeleteConfirmationDialog from "@/sharedComponents/DeleteConfirmationDialog";
import SearchFieldRowWithPagination from "@/sharedComponents/SearchFieldRowWithPagination";
import DatabasesTable from "@/views/Databases/components/DatabasesTable";
import AddNewDatabaseDialog from "@/views/Databases/components/AddNewDatabaseDialog";
import EditDatabaseDialog from "@/views/Databases/components/EditDatabaseDialog";

export default {
  name: "ManageDatabaseList",
  metaInfo: {
    title: 'Manage Databases'
  },
  components: {
    PageHeader,
    DatabasesTable,
    AddNewDatabaseDialog,
    DeleteConfirmationDialog,
    SearchFieldRowWithPagination,
    EditDatabaseDialog,
  },
  data() {
    return {
      databases: [],
      tablePageNumber: 1,
      tablePageSize: 25,
      totalCount: 0,
      searchString: '',
      addDialog: false,
      editDialog: false,
      editedDatabase: null,
      deleteDialog: false,
      deleteItemId: null,
    };
  },
  created() {
    this.fetchAvailableDatabases();
  },
  methods: {
    async handleChangePageSize(size) {
      this.tablePageSize = size;
      this.tablePageNumber = 1;
      await this.fetchAvailableDatabases();
    },
    async handleNextPage() {
      this.tablePageNumber += 1;
      await this.fetchAvailableDatabases();
    },
    async handlePrevPage() {
      this.tablePageNumber -= 1;
      await this.fetchAvailableDatabases();
    },
    async handleSearch(keyword) {
      this.searchString = keyword;
      this.tablePageNumber = 1;
      await this.fetchAvailableDatabases();
    },
    async fetchAvailableDatabases() {
      try {
        const resp = await this.$rest.admin.getDatabaseCollection({
          page: this.tablePageNumber,
          limit: this.tablePageSize,
          hostname: this.searchString,
        });
        this.databases = resp.data.items;
        this.totalCount = resp.data.totalCount;
      } catch (error) {
        console.error('error: ', error);
      }
    },
    async handleAction(action) {
      if (action.event.value === "delete") {
        this.deleteItemId = action.item.id
        this.deleteDialog = true
      }
      if (action.event.value === "edit") {
        this.editedDatabase = action.item
        this.editDialog = true;
      }
    },
    async addDatabase(data) {
      await this.$rest.admin.postDatabaseResource(data);

      this.addDialog = false;

      await this.fetchAvailableDatabases({});
    },
    async editDatabase(data) {
      await this.$rest.admin.put_database_resource(data.id, data);

      this.editDialog = false;

      await this.fetchAvailableDatabases({});
    },
    async deleteDatabase() {
      const resp = await this.$rest.admin.deleteDatabaseResource(this.deleteItemId);
      if (resp.status === 204) {
        this.$store.commit('snackbar/showMessage', {
          color: "info",
          content: "Successfully deleted the database.",
        });

        await this.fetchAvailableDatabases({});
      } else if (resp.status === 200) {
        this.$store.commit('snackbar/showMessage', {
          color: "error",
          content: resp.data,
        });
      }

      this.deleteDialog = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.mobile-hint, .scroll-title {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  color: #66788e;
}
::v-deep .field__subheader {
  font-size: 15px !important;
  font-weight: 600 !important;
  color: #241c15;
}
::v-deep .v-input--radio-group__input {
  .v-label {
    font-size: 13px !important;
  }
}
</style>
